<template>
    <div
        v-if="text !== 'KERN' && text !== 'FK'"
        :class="{ 'product-label': true, 'full-radius': !radiusRight }"
        @mouseenter="mouseEnterInfo($event)"
        @mouseleave="mouseLeaveInfo"
        @click="clickEvent"
    >
        <div class="text">
            {{ text }}
        </div>
        <div :class="`color color-${text.toLowerCase().replace(' ', '-')}`"/>

        <Teleport to="body" :disabled="!renderTooltipInBody">
            <AtomTooltip :element="tooltipElement" :offset-y="10" tooltip-type="default">
                {{ $t(`Product.${text.toLowerCase().replace(' ', '_')}_tooltip`) }}
            </AtomTooltip>
        </Teleport>
    </div>
</template>
<script lang="ts" setup>
withDefaults(defineProps<{
    text: string,
    icon?: string
    radiusRight?: boolean,
    renderTooltipInBody?: boolean,
}>(), {
    icon: '',
    radiusRight: false,
    renderTooltipInBody: false,
});

const tooltipElement = ref<HTMLElement | null>(null);

const mouseEnterInfo = (event: MouseEvent) => {
    tooltipElement.value = event.target as HTMLElement;
};

const mouseLeaveInfo = () => {
    tooltipElement.value = null;
};

const clickEvent = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
};
</script>
<style scoped lang="scss">
.product-label {
    padding: sp(xxs) sp(xs);
    height: rem(24);
    width: fit-content;
    text-align: center;
    cursor: default;
    display: flex;
    align-items: center;

    @include helper-color-bg(white);
    @include helper-color(text-title);
    @include helper-border-radius-r(rem(16));
    @include helper-border($setting-color-gray-1, rem(1), solid);
    @include helper-font-weight(medium);

    .text {
        margin-right: sp(xxs);
        margin-top: rem(-1);
    }

    &.full-radius {
        @include helper-border-radius(rem(16));
    }

    .color {
        width: rem(12);
        height: rem(12);

        @include helper-border-radius-circle();

        &-nos {
            @include helper-color-bg(label-nos);
        }

        &-saison {
            @include helper-color-bg(label-saison);
        }

        &-fk {
            @include helper-color-bg(label-fk);
        }

        &-kern {
            @include helper-color-bg(label-kern);
        }

        &-ps {
            @include helper-color-bg(label-ps);
        }

        &-ms {
            @include helper-color-bg(label-ms);
        }

        &-w {
            @include helper-color-bg(label-w);
        }

        &-netto {
            @include helper-color-bg(label-netto);
        }

        &-cat-o {
            @include helper-color-bg(label-cat-o);
        }

        &-cat-op {
            @include helper-color-bg(label-cat-op);
        }
    }
}
</style>
